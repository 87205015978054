* {
    box-sizing: border-box;
    /* outline: 1px dashed #f00; */
}

body {
    margin: 5em;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--colorNeutralBackground2);
    position: relative;
}
