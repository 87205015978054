.pre-wrap {
    white-space: pre-wrap;
}

.padding-top-bottom {
    height: 100vh;
    padding: 30px 0px;
    background-color: var(--colorNeutralBackground2);
}

.main-container {
    height: 90vh;
    margin: 0 70px;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
        0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    overflow: auto;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    background-color: var(--colorNeutralBackground4);
}

.title {
    margin: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title h2 {
    font-size: 24px;
    padding-left: 10;
    margin: 0;
    display: inline;
    font-weight: 600;
}

.auth {
    margin-top: 30vh;
    font-size: 18px;
    background-color: var(--colorNeutralBackground3);
}

.auth button {
    font-size: 16px;
    text-align: center;
    display: block;
    margin: 32px auto;
}

.auth h3 {
    margin-left: 10px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
}
.auth p {
    margin-left: 10px;
    font-size: 16px;
    text-align: center;
}

html,
body {
    height: 50%;
}

.contents {
    flex: 1;
    overflow: auto;
}

.mgt-col {
    float: left;
    width: 50%;
    background: transparent;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 10px;
}

.underline-on-hover:hover {
    text-decoration: underline;
}

.hover-file:hover {
    background-color: var(--colorBrandBackground2Hover);
}

.list {
    height: 100%;
    overflow-y: auto;
}

.tiles-modal {
    max-width: 90vw;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.analysis-card {
    flex-grow: 1;
    background: var(--colorNeutralBackground3);
    padding: 10px;
    padding-top: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    transition: all 0.1s ease-in-out;

    &:hover {
        box-shadow: var(--colorBrandShadowAmbient) 0px 10px 20px -12px;

        .card-icon-container {
            transform: scale(1.07);
        }
    }

    .card-label {
        flex: 1; /* Make the label take up remaining space */
        font-size: 13px;
        margin-top: 10px; /* Add margin to space from the icon */
        text-align: center; /* Center the text horizontally */

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .card-icon-container {
        width: 40px;
        height: 40px;

        color: currentColor;
        position: relative;
        z-index: 0;

        box-shadow: rgba(0, 0, 0, 0.1) 0px 25px 50px -12px;

        transition: transform 0.15s ease-in-out;
        flex-shrink: 0; /* Prevent shrinking */

        &::after {
            content: "";
            display: block;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            position: absolute;

            z-index: -1;
            background: currentColor;
            opacity: 0.2;
        }
    }
}

/* overwrite property of consono-rag webcomponent */
.main-flex-container {
    height: 75vh !important;
}

.summary-wrapper {
    margin: 0px 5px;
    overflow-y: auto;

    /* overwrite properties of consono-summary webcomponent */
    .component-container {
        padding: 5px !important;
        margin: 0px !important;
        color: var(--colorNeutralForeground1) !important;

        .component-detail-header-container {
            background-color: var(--colorNeutralBackground1Hover) !important;
            border-color: var(--colorNeutralBackground1Hover) !important;
        }
    }
}
